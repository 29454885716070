export const environment = {
  production: false,
  backendURL: 'https://myaccount-dev.app.karcher.com/api',
  translation: 'https://myaccount-dev.app.karcher.com/translation',
  configuration: 'https://myaccount-dev.app.karcher.com',
  corporateUrl: 'https://cms-stage.app.kaercher.com',
  loginUrl: 'https://auth.dev.karcher.com',
  notFoundUrl: 'https://www.kaercher.com/int/404.html',
  clientId: 'mykaercher-web',
  clientSecret: 'codRLhHxmsNlIeRFSxwXOZYCW66EwDyUlOTgnnQA',
  rock: 'https://ak-rock-dev-srm-app.s3.eu-central-1.amazonaws.com/remoteEntry.js',
  analyticsUrl:
    'https://assets.adobedtm.com/a13643f4feff/095e7dd7396b/launch-0d2ababb54fa-staging.min.js',
  recaptcha: {
    siteKey: '6Lcc8UopAAAAAPtHgslUUpEk566I2X6ogXfEAmF6',
  },
};
